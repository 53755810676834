import React from "react";
import { ArrowRight, ArrowRightCircle } from "react-feather";
import { Link } from 'react-router-dom';

class Portfolio extends React.Component {
  researchData = require('../core/dictionary/research-dictionary').getResearchDataDictionary();
  researchType = require('../core/dictionary/research-type-dictionary').getResearchTypeDictionary();
  constructor() {
    super();
    this.state = {
      portfolio: this.researchData,
      currentType: this.researchType.all
    };
  }
  setCurrentResearchState = (data) => {
    this.setState({
      currentType: data
    });
    if(data === this.researchType.all) {
      this.setState({
        portfolio: this.researchData
      });
    } else {
      this.setState({
        portfolio: this.mapResearchStateData(data)
      });
    }
  }
  mapResearchStateData = (data) => {
    let resultArr = [];
    for(let i = 0; i < this.researchData.length; i++ ) {
      if(this.researchData[i].type.includes(data)) {
        resultArr.push(this.researchData[i]);
      }
    }
    return resultArr;
  }
  render() {
    return (
      <section id="work" className="portfolio-mf sect-pt4 route">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="title-box text-center">
                <h3 className="title-a">Research</h3>
                <div className="line-mf"></div>
              </div>
            </div>
          </div>
          <div className="row mb-5">
            <div className="col-12 text-center">
              <span className={this.researchType.all === this.state.currentType ? 'btn btn-outline-secondary js-scroll category-type-selected' : 'btn btn-outline-secondary js-scroll' } onClick={this.setCurrentResearchState.bind(this, this.researchType.all)}>All </span>
              <span className={this.researchType.cognitiveLoad === this.state.currentType ? 'btn btn-outline-secondary js-scroll category-type-selected mx-md-4' : 'btn btn-outline-secondary js-scroll mx-md-4' } onClick={this.setCurrentResearchState.bind(this, this.researchType.cognitiveLoad)}>Cognitive Load </span>
              <span className={this.researchType.emotions === this.state.currentType ? 'btn btn-outline-secondary js-scroll category-type-selected' : 'btn btn-outline-secondary js-scroll'} onClick={this.setCurrentResearchState.bind(this, this.researchType.emotions)}>Emotions </span>
              <span className={this.researchType.physiology === this.state.currentType ? 'btn btn-outline-secondary js-scroll category-type-selected mx-md-4' : 'btn btn-outline-secondary js-scroll mx-md-4'} onClick={this.setCurrentResearchState.bind(this, this.researchType.physiology)}>Physiology </span>
              <span className={this.researchType.trust === this.state.currentType ? 'btn btn-outline-secondary js-scroll category-type-selected' : 'btn btn-outline-secondary js-scroll'} onClick={this.setCurrentResearchState.bind(this, this.researchType.trust)}>Trust </span>
              <span className={this.researchType.virtualReality === this.state.currentType ? 'btn btn-outline-secondary js-scroll category-type-selected ml-md-4' : 'btn btn-outline-secondary js-scroll ml-md-4'} onClick={this.setCurrentResearchState.bind(this, this.researchType.virtualReality)}>Virtual Reality </span>
            </div>
          </div>
          <div className="row">
          {this.state.portfolio.map(content => {
            return (
              <div className="col-md-4 mb-4" key={content.id}>
                <div className="work-box">
                  <Link to={'research/'+ content.id}>
                    <div className="work-img">
                      <img src={content.imgURL} alt="" className="img-fluid" />
                      <div className="middle">
                        <div className="text"><ArrowRightCircle size={40}/></div>
                      </div>
                    </div>
                    </Link>
                    <div className="work-content">
                      <div className="row">
                          <div className="col-sm-12">
                            <Link to={'research/'+ content.id}><h2 className="w-title">{content.head}</h2></Link>
                            <div className="w-more">
                                <span className="w-ctegory">
                                {content.place}
                                </span>
                            </div>
                          </div>
                          <div className="col-sm-12 pt-3 text-center">
                            <Link className="w-category" to={'research/'+ content.id}>Read More <ArrowRight style={{fontSize: 10+ 'px'}}/></Link>
                          </div>
                          {/* <div className="col-sm-2">
                            <div className="w-like">
                                <span className="ion-ios-plus-outline"></span>
                            </div>
                          </div> */}
                      </div>
                    </div>
                </div>
              </div>         
            );
          })}
          </div>
        </div>
      </section>
    );
  }
}

export default Portfolio;
