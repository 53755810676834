import React from 'react';
import Routes from './routes';
function App() {
  return (
    <div>
        <Routes />
    </div>
  );
}

export default App;