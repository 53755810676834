const getResearchDataDictionary = function(data){
    const researchDataDictionary = [
        {
          id: "siggasiaxr2021",
          type: ["all", "emotions", "physiology", "virtual-reality"],
          imgURL: "https://res.cloudinary.com/kunal-gupta/image/upload/v1640073493/kunalgupta.in/Research/7%20WizardOfVR/WizardOfVR_representativeImage1_j1eooz.jpg",
          head: "WizardOfVR: An Emotion-Adaptive Virtual Wizard Experience",
          place: "Siggraph Asia 2021",
          detailsContent: {
            title: "WizardOfVR: An Emotion-Adaptive Virtual Wizard Experience",
            headerBGImage: "https://res.cloudinary.com/kunal-gupta/image/upload/v1640073500/kunalgupta.in/Research/7%20WizardOfVR/teaser_gkmv9g.png",
            videoUrl: "https://www.youtube.com/embed/8ku4wowpqUI",
            imageUrl: [""],
            abstractContent: "We demonstrate WizardOfVR, a personalized emotion-adaptive Virtual Reality (VR) game akin to a Harry Potter experience, which uses using off-the-shelf physiological sensors to create a real-time biofeedback loop between a user’s emotional state and an adaptive VR environment (VRE). In our demo, the user initially trains the system during a calibration process using Electroencephalogram (EEG), Electrodermal Activity (EDA), and Heart Rate Variability (HRV) physiological signals. After calibration, the user will explore a virtual forest with adapting environmental factors based on a ’SanityMeter’ determined by the user’s real-time emotional state. The overall goal is to provide more balanced, immersive, and optimal emotional virtual experiences.",
            isInProgress: true,
            testimonialContent: "",
            event: "Siggraph Asia XR Demo 2021, Hybrid Tokyo",
            paperUrl: ""
          }
        },
        {
          id: "mobilehci2021",
          type: ["all", "emotions", "physiology", "virtual-reality"],
          imgURL: "https://res.cloudinary.com/kunal-gupta/image/upload/v1640073501/kunalgupta.in/Research/6%20MobileHCI/setup_ekiyvw.png",
          head: "Towards Understanding Physiological Responses to Emotional Autobiographical Memory Recall in Mobile VR Scenarios",
          place: "Mobile HCI 2021",
          detailsContent: {
            title: "Towards Understanding Physiological Responses to Emotional Autobiographical Memory Recall in Mobile VR Scenarios",
            headerBGImage: "https://res.cloudinary.com/kunal-gupta/image/upload/v1640073500/kunalgupta.in/Research/6%20MobileHCI/teaser2_fctoba.png",
            videoUrl: "https://www.youtube.com/embed/anRzHpwcARI",
            imageUrl: ["", ""],
            abstractContent: "Virtual Reality (VR) is becoming increasingly mobile and ubiquitous but the experience is still rarely tailored to a person’s individual experience and emotion. Autobiographical memories (AM) are better remembered when an event is associated with strong emotional experiences. In this work, we introduce an experimental paradigm in VR to investigate the effect of emotional AM recall on a user’s physiological state when coupled with wearable electrodermal activity (EDA) sensors and integrated pupil trackers. With an objective to benchmark the effect of AM recall on human physiology, we replicated the Autobiographical Memory Test (AMT) in VR which displays positive, negative, and neutral valence words to participants for them to recall self-relevant memories associated with that word. We found that there was a positive effect of AM recall on EDA peak amplitude, EDA peak number, and pupil diameter when compared with no recall. However, there was no impact on emotional AM recall. Finally, we discuss the limitations and possibilities of leveraging autobiographical memory to create personalized mobile VR experiences when used with wearable physiological sensors.",
            isInProgress: false,
            testimonialContent: "Gupta, Kunal, Sam WT Chan, Yun Suen Pai, Alexander Sumich, Suranga Nanayakkara, and Mark Billinghurst. 'Towards Understanding Physiological Responses to Emotional Autobiographical Memory Recall in Mobile VR Scenarios.' In Adjunct Publication of the 23rd International Conference on Mobile Human-Computer Interaction, pp. 1-5. 2021.",
            event: "Mobile HCI 2021, Toulouse & Virtual France",
            paperUrl: "https://dl.acm.org/doi/abs/10.1145/3447527.3474864"
          }
        },
        {
          id: "sigasiatc2020",
          type: ["all", "physiology", "virtual-reality"],
          imgURL: "https://res.cloudinary.com/kunal-gupta/image/upload/v1640073501/kunalgupta.in/Research/5%20NeuralDrum/Photo.PNG_ef0x3z.png",
          head: "NeuralDrum: Perceiving Brain Synchronicity in XR Drumming",
          place: "Siggraph Asia 2020",
          detailsContent: {
            title: "NeuralDrum: Perceiving Brain Synchronicity in XR Drumming",
            headerBGImage: "https://res.cloudinary.com/kunal-gupta/image/upload/v1640073502/kunalgupta.in/Research/5%20NeuralDrum/neuraldrum.PNG_fmher5.png",
            videoUrl: "https://www.youtube.com/embed/iH5bTxVhtB8",
            imageUrl: ["", ""],
            abstractContent: "Brain synchronicity is a neurological phenomenon where two or more individuals have their brain activation in the phase when performing a shared activity. We present NeuralDrum, an extended reality (XR) drumming experience that allows two players to drum together while their brain signals are simultaneously measured. We calculate the Phase Locking Value (PLV) to determine their brain synchronicity and use this to directly affect their visual and auditory experience in the game, creating a closed feedback loop. In a pilot study, we logged and analyzed the users’ brain signals as well as had them answer a subjective questionnaire regarding their perception of synchronicity with their partner and the overall experience. From the results, we discuss design implications to further improve NeuralDrum and propose methods to integrate brain synchronicity into interactive experiences.",
            isInProgress: false,
            testimonialContent: "Pai, Yun Suen, Ryo Hajika, Kunal Gupta, Prasanth Sasikumar, and Mark Billinghurst. 'NeuralDrum: Perceiving Brain Synchronicity in XR Drumming.' In SIGGRAPH Asia 2020 Technical Communications, pp. 1-4. 2020.",
            event: "Siggraph Asia 2020, Virtual Event Republic of Korea",
            paperUrl: "https://dl.acm.org/doi/abs/10.1145/3410700.3425434"
          }
        },
        {
          id: "ieeevr2020",
          type: ["all", "cognitive-load", "physiology", "trust", "virtual-reality"],
          imgURL: "https://res.cloudinary.com/kunal-gupta/image/upload/v1640073503/kunalgupta.in/Research/3%20Measure%20Human%20Trust/measuring_thumbnail.PNG_pgavwh.png",
          head: "Measuring Human Trust in a Virtual Assistant using Physiological Sensing in VR",
          place: "IEEE VR 2020, Atlanta",
          detailsContent: {
            title: "Measuring Human Trust in a Virtual Assistant using Physiological Sensing in VR",
            headerBGImage: "https://res.cloudinary.com/kunal-gupta/image/upload/v1640073499/kunalgupta.in/Research/3%20Measure%20Human%20Trust/measuring_trust_dccta3.png",
            videoUrl: "https://www.youtube.com/embed/cwbw7Pt5eDs",
            imageUrl: ["https://res.cloudinary.com/kunal-gupta/image/upload/v1640073499/kunalgupta.in/Research/3%20Measure%20Human%20Trust/measuring_trust_dccta3.png", ""],
            abstractContent: "With the advancement of Artificial Intelligence technology to make smart devices, understanding how humans develop trust in virtual agents is emerging as a critical research field. Through our research, we report on a novel methodology to investigate users’ trust in auditory assistance in a Virtual Reality (VR) based search task, under both high and low cognitive load and under varying levels of agent accuracy. We collected physiological sensor data such as electroencephalography (EEG), galvanic skin response (GSR), and heart-rate variability (HRV), subjective data through questionnaires such as System Trust Scale (STS), Subjective Mental Effort Questionnaire (SMEQ), and NASA-TLX. We also collected a behavioral measure of trust (congruency of users’ head motion in response to valid/ invalid verbal advice from the agent). Our results indicate that our custom VR environment enables researchers to measure and understand human trust in virtual agents using the matrices, and both cognitive load and agent accuracy play an important role in trust formation. We discuss the implications of the research and directions for future work.",
            isInProgress: false,
            testimonialContent: "Gupta, Kunal, Ryo Hajika, Yun Suen Pai, Andreas Duenser, Martin Lochner, and Mark Billinghurst. 'Measuring human trust in a virtual assistant using physiological sensing in virtual reality.' In 2020 IEEE Conference on Virtual Reality and 3D User Interfaces (VR), pp. 756-765. IEEE, 2020.",
            event: "IEEE VR 2020, Atlanta",
            paperUrl: "https://ieeexplore.ieee.org/abstract/document/9089632"
          }
        },
        {
          id: "sigasiaxr2019",
          type: ["all", "physiology", "virtual-reality"],
          imgURL: "https://res.cloudinary.com/kunal-gupta/image/upload/v1640073503/kunalgupta.in/Research/2%20Hyperdrum/Thumbnail_ap1rva.png",
          head: "Hyperdrum: Interactive Synchronous Drumming in Virtual Reality using Everyday Objects",
          place: "SIGGRAPH Asia 2019, Brisbane",
          detailsContent: {
            title: "Hyperdrum: Interactive Synchronous Drumming in Virtual Reality using Everyday Objects",
            headerBGImage: "https://res.cloudinary.com/kunal-gupta/image/upload/v1640073503/kunalgupta.in/Research/2%20Hyperdrum/teaser_image_f7i5v9.png",
            videoUrl: "https://www.youtube.com/embed/zJelYqKnwKI",
            imageUrl: ["", ""],
            abstractContent: "Hyperscanning is a method to detect if brain wave synchronicity exists between two or more individuals, which is usually due to behavioral or social interactions. It is usually limited to neuroscience studies and is very rarely used as an interaction or visual feedback mechanic. In this work, we propose HyperDrum, which is about leveraging this cognitive synchronization to create a collaborative music production experience with immersive visualization in virtual reality. We let the participants wear electroencephalography (EEG) head-mounted displays to create music together using a physical drum. As the melody becomes in synced, we perform hyperscanning to evaluate the degree of synchronicity. The produced music and visualization reflect the synchronicity level while at the same time training the participants to create music together, enriching the experience and performance. HyperDrum’s main goal is twofold; to blend cognitive neuroscience with creativity in VR, and to encourage connectivity between humans using both art and science.",
            isInProgress: false,
            testimonialContent: "Hajika, Ryo, Kunal Gupta, Prasanth Sasikumar, and Yun Suen Pai. 'HyperDrum: Interactive Synchronous Drumming in Virtual Reality using Everyday Objects.' In SIGGRAPH Asia 2019 XR, pp. 15-16. 2019.",
            event: "SIGGRAPH Asia 2019, Brisbane",
            paperUrl: "https://dl.acm.org/doi/abs/10.1145/3355355.3361894"
          }
        },
        {
          id: "vrst2020",
          type: ["all", "emotions", "physiology", "virtual-reality"],
          imgURL: "https://res.cloudinary.com/kunal-gupta/image/upload/v1642671258/kunalgupta.in/ezgif.com-gif-maker_rc5jsi.gif",
          head: "AffectivelyVR: Towards VR Personalized Emotion Recognition",
          place: "VRST 2020",
          detailsContent: {
            title: "AffectivelyVR: Towards VR Personalized Emotion Recognition",
            headerBGImage: "https://res.cloudinary.com/kunal-gupta/image/upload/v1642672207/kunalgupta.in/Research/4%20AffectivelyVR/Phase1_tvcwgc.png",
            videoUrl: "https://www.youtube.com/embed/IhTuurhUzc4",
            imageUrl: ["https://res.cloudinary.com/kunal-gupta/image/upload/v1642671258/kunalgupta.in/ezgif.com-gif-maker_rc5jsi.gif", "https://res.cloudinary.com/kunal-gupta/image/upload/v1640073504/kunalgupta.in/Research/4%20AffectivelyVR/cEFqSducChlY6zaigZdIMml1cFk48hDKc1G9j-3yjLwFRXmB1s_m6zbx8wRdYZQggxAz5qCSWEF-dwqrfD_xLJo0ZDzJ36yx6faORhFI3UNx11yjHSs8UshoOUvS1SxZbpLl_RG2lk0_s1600_ixyoth.gif", "https://res.cloudinary.com/kunal-gupta/image/upload/v1640073502/kunalgupta.in/Research/4%20AffectivelyVR/affectivelyVR_qdzhsw.png"],
            abstractContent: "We present AffectivelyVR, a personalized real-time emotion recognition system in Virtual Reality (VR) that enables an emotion-adaptive virtual environment. We used off-the-shelf Electroencephalogram (EEG) and Galvanic Skin Response (GSR) physiological sensors to train user-specific machine learning models while exposing users to affective 360° VR videos. Since emotions are largely dependent on interpersonal experiences and expressed in different ways for different people, we personalize the model instead of generalizing it. By doing this, we achieved an emotion recognition rate of 96.5% using the personalized KNN algorithm, and 83.7% using the generalized SVM algorithm.",
            isInProgress: false,
            testimonialContent: "Gupta, Kunal, Jovana Lazarevic, Yun Suen Pai, and Mark Billinghurst. 'AffectivelyVR: Towards VR Personalized Emotion Recognition.' In 26th ACM Symposium on Virtual Reality Software and Technology, pp. 1-3. 2020.",
            event: "VRST 2020, Virtual Event Canada",
            paperUrl: "https://dl.acm.org/doi/abs/10.1145/3359996.3364276"
          }
        },
        {
          id: "vrst2019",
          type: ["all", "cognitive-load", "emotions", "physiology", "trust", "virtual-reality"],
          imgURL: "https://res.cloudinary.com/kunal-gupta/image/upload/v1640073491/kunalgupta.in/Research/1%20In%20AI%20We%20Trust/thumbnail_vjlvjr.gif",
          head: "In AI We Trust: Investigating the relationship between biosignals, trust, and cognitive load in VR",
          place: "VRST 2019",
          detailsContent: {
            title: "In AI We Trust: Investigating the relationship between biosignals, trust, and cognitive load in VR",
            headerBGImage: "https://res.cloudinary.com/kunal-gupta/image/upload/v1640073502/kunalgupta.in/Research/1%20In%20AI%20We%20Trust/test-header-02_xkhgbr.png",
            videoUrl: "https://www.youtube.com/embed/CqCqvrePwus",
            imageUrl: ["", ""],
            abstractContent: "Human trust is a psycho-physiological state that is difficult to measure, yet is becoming increasingly important for the design of human-computer interactions. This paper explores if human trust can be measured using physiological measures when interacting with a computer interface, and how it correlates with cognitive load. In this work, we present a pilot study in Virtual Reality (VR) that uses a multi-sensory approach of Electroencephalography (EEG), galvanic skin response (GSR), and Heart Rate Variability (HRV) to measure trust with a virtual agent and explore the correlation between trust and cognitive load. The goal of this study is twofold; 1) to determine the relationship between biosignals, or physiological signals with trust and cognitive load, and 2) to introduce a pilot study in VR based on cognitive load level to evaluate trust. Even though we could not report any significant main effect or interaction of cognitive load and trust from the physiological signal, we found that in low cognitive load tasks, EEG alpha band power reflects trustworthiness on the agent. Moreover, cognitive load of the user decreases when the agent is accurate regardless of task’s cognitive load. This could be possible because of small sample size, tasks not stressful enough to induce high cognitive load due to lab study and comfortable environment or timestamp synchronisation error due to fusing data from various physiological sensors with different sample rate.",
            isInProgress: false,
            testimonialContent: "Gupta, Kunal, Ryo Hajika, Yun Suen Pai, Andreas Duenser, Martin Lochner, and Mark Billinghurst. 'In ai we trust: Investigating the relationship between biosignals, trust and cognitive load in vr.' In 25th ACM Symposium on Virtual Reality Software and Technology, pp. 1-10. 2019.",
            event: "VRST 2019, Sydney",
            paperUrl: "https://dl.acm.org/doi/abs/10.1145/3359996.3364276"
          }
        }
    ]
    return researchDataDictionary;
 };
exports.getResearchDataDictionary = getResearchDataDictionary;
