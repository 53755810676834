import React from 'react';
import Navbar from '../components/navbar';
import Intro from '../components/intro';
import About from '../components/about';
import Portfolio from '../components/portfolio';
import Contact from '../components/contact';
import BackToTop from '../components/back-top';
import Preloader from '../components/preloader';
import Footer  from '../components/footer';
function Home() {
  return (
    <div>
        <Navbar />
        <Intro />
        <Portfolio />
        <About />
        <Contact />
        <BackToTop />
        <Preloader />
        <Footer/>
    </div>
  );
}

export default Home;