import React, { Suspense }  from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Home from '../src/routes/home';
import Research from '../src/routes/research-details';
export default function Routes() {
  return (
    <Router>
      {/* fallback={<div className="loader-container">
        <div className="loader-block">
          <img src="" alt="description of gif" /> 
        </div>
      </div>} */}
      <Suspense>
      <Switch> 
        <Route exact path="/" component={Home}></Route>
        <Route exact path="/research/:id" component={Research}></Route>
        {/* <Route exact path="/404" component={Error}></Route>
        <Route component={Error} /> */}
      </Switch>
      </Suspense>
    </Router>
  );
}
