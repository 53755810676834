const getResearchTypeDictionary = function(data){
    const researchTypeDictionary = {
      all: 'all',
      cognitiveLoad: 'cognitive-load',
      emotions: 'emotions',
      physiology: 'physiology',
      trust: 'trust',
      virtualReality: 'virtual-reality'
    }
    return researchTypeDictionary;
 };
exports.getResearchTypeDictionary = getResearchTypeDictionary;
