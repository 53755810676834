import React from "react";
import moment from 'moment-timezone';
class Contact extends React.Component {
  componentDidMount() {
    var date = new Date();
    var nzlandTime = moment.tz(date, "Pacific/Auckland").format("HH mm A");
    this.setState({
      nzlandCurrentTime: nzlandTime
    });
  }
  render() {
    return (
      <section>
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="contact-mf">
                <div id="contact" className="box-shadow-full">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="title-box-2 pt-4 pt-md-0">
                        <h5 className="title-left">Location</h5>
                      </div>
                      <div>
                        <iframe 
                          className="col-12 p-0"
                          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3192.588180029826!2d174.7669186152914!3d-36.85233777993783!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6d0d47e383f32253%3A0xbd49f61f758a9e5b!2sThe%20University%20of%20Auckland!5e0!3m2!1sen!2sin!4v1641745178347!5m2!1sen!2sin" 
                          width="100%"
                          height="380"
                          style={{boxShadow: '0 1px 1px 0 rgba(0, 0, 0, 0.06), 0 2px 5px 0 rgba(0, 0, 0, 0.2)', border:0}}
                          allowfullscreen="" 
                          loading="lazy">
                        </iframe>
                        </div>
                    </div>
                    <div className="col-md-6">
                      <div className="title-box-2 pt-4 pt-md-0">
                        <h5 className="title-left">Get in Touch</h5>
                      </div>
                      <div className="more-info">
                        <p className="lead">You Made It So Far</p>
                        <p className="lead">Thanks for dropping by!</p>
                        <p className="lead">Since you have made it this far, why don’t you send me a message on</p>
                        <p className="lead"><a className="color-a" href="https://www.linkedin.com/in/kgupta2789/">LinkedIn</a> or reach me out at <a className="color-a" href="mailto:hello@kunalgupta.in">hello [at] kunalgupta [dot] in</a>.</p>
                        <p className="lead">I would love to hear more about you and your work!</p>
                        {!!this && !!this.state && !!this.state.nzlandCurrentTime && this.state.nzlandCurrentTime !== '' ? <p className="lead">My local time is  {this.state.nzlandCurrentTime}</p> : <></> }
                      </div>
                      <div>
                  </div>
                      <div className="socials">
                        <ul>
                        <li>
                            <a
                              href="https://scholar.google.com/citations?user=vyLj2L8AAAAJ&hl=en"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <span className="ico-circle">
                                <i className="ion-university"></i>
                              </span>
                            </a>
                          </li>
                          <li>
                            <a
                              href="mailto:hello@kunalgupta.in"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <span className="ico-circle">
                                <i className="ion-android-mail"></i>
                              </span>
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://twitter.com/kay_J33"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <span className="ico-circle">
                                <i className="ion-social-twitter"></i>
                              </span>
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.linkedin.com/in/kgupta2789/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <span className="ico-circle">
                                <i className="ion-social-linkedin"></i>
                              </span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer>
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="copyright-box">
                </div>
              </div>
            </div>
          </div>
        </footer>
      </section>
    );
  }
}

export default Contact;
