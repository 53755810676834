import React from "react";
import "./../components/stars.scss";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import { ChevronRight, ChevronLeft } from "react-feather";
import { getWebsiteUrl } from "../core/utils/websiteUrls";
class Research extends React.Component {
    researchData = require('../core/dictionary/research-dictionary').getResearchDataDictionary();
    constructor(props) {
        super();
        this.state = {
            researchDataElement : {},
            leftUrl: '',
            rightUrl: '',
        }
    }
    componentDidMount() {
        // console.log('The PathName is ', this.props.match.params.id); // This way you will get the ID
        // console.log('The Research Data is ', this.researchData);
        this.findresearchData(this.props.match.params.id);
        window.scrollTo(0, 0);
    }
    findresearchData = (data) => {
        console.log('The Website Url is ', getWebsiteUrl());
        if (!!data && data !== '') {
        let tempElement = {};
        for(let i = 0; i < this.researchData.length ; i++) {
          if(data === this.researchData[i].id) {
            tempElement = this.researchData[i];
            if(i === this.researchData.length-1) {
              this.setState({
                  leftUrl: getWebsiteUrl() + 'research/'+ this.researchData[i-1].id,
                  rightUrl: getWebsiteUrl() + 'research/'+ this.researchData[0].id,
              });  
            } else if(i === 0) {
                this.setState({
                    leftUrl: getWebsiteUrl() + 'research/'+ this.researchData[this.researchData.length-1].id,
                    rightUrl: getWebsiteUrl() + 'research/'+ this.researchData[i+1].id,
                });
            } else {
                this.setState({
                    leftUrl: getWebsiteUrl() + '/research/'+ this.researchData[i-1].id,
                    rightUrl: getWebsiteUrl() + '/research/'+ this.researchData[i+1].id,
                });
            }
            break;
          }
        }
        this.setState({
            researchDataElement : tempElement
        })
        }
    }
    render() {
        return (
            <>
            <Navbar/>
            <div className="position-relative py-5">
            {!!this.state.researchDataElement && !!this.state.researchDataElement.detailsContent ? 
                <div className="container-fluid">
                     <div className="row gx-4 gx-lg-5 align-items-center my-5 text-center">
                     <div className="container-img w-100">
                        <img className="img-fluid w-100" src={!!this.state.researchDataElement.detailsContent.headerBGImage ? this.state.researchDataElement.detailsContent.headerBGImage : ''} alt="..."/>
                        <h3 className="centered">{!!this.state.researchDataElement.detailsContent.title ? this.state.researchDataElement.detailsContent.title : ''}</h3>
                    </div>
                     </div>
                    <div className="container text-center">
                     {!!this.state.researchDataElement.detailsContent.videoUrl && this.state.researchDataElement.detailsContent.videoUrl !== '' ? <div className="row gx-4 gx-lg-5 align-items-center my-md-5 mob-top-margin">
                        <div className="col-lg-12">
                          <iframe width="80%" height="400" src={!!this.state.researchDataElement.detailsContent.videoUrl ? this.state.researchDataElement.detailsContent.videoUrl : ''} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        </div>
                     </div> : <></>}
                     {/* {!!this.state.researchDataElement.detailsContent.videoUrl && this.state.researchDataElement.detailsContent.videoUrl !== '' ? <div className="row gx-4 gx-lg-5 align-items-center my-5">
                        <div className="col-lg-12">
                          <iframe width="80%" height="400" src={!!this.state.researchDataElement.detailsContent.videoUrl ? this.state.researchDataElement.detailsContent.videoUrl : ''} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        </div>
                     </div> : <></>} */}

                     <div className="row gx-4 gx-lg-5 align-items-center my-5">
                        <div className="col-lg-12 text-left">
                           <h3>Abstract</h3>
                           <p className="text-black">{!!this.state.researchDataElement.detailsContent.abstractContent ? this.state.researchDataElement.detailsContent.abstractContent : ''}</p>
                        </div>
                     </div>
                     {!!this.state.researchDataElement.detailsContent.testimonialContent ? 
                        <div className="card">
                        <div className="card-body">
                            <blockquote className="blockquote mb-0">
                            <footer className="blockquote-footer pb-0"><cite title="Source Title">{this.state.researchDataElement.detailsContent.testimonialContent}</cite></footer>
                            </blockquote>
                        </div>
                    </div> : <></>}
                    <p className="text-black my-5"><strong>{!!this.state.researchDataElement.detailsContent.event ? this.state.researchDataElement.detailsContent.event : ''}</strong></p>
                    {!!this.state.researchDataElement.detailsContent.paperUrl && this.state.researchDataElement.detailsContent.paperUrl ? <p className="pt-3">
                              <a
                                 className="btn btn-outline-dark js-scroll"
                                 href={!!this.state.researchDataElement.detailsContent.paperUrl ? this.state.researchDataElement.detailsContent.paperUrl : ''}
                                 role="button"
                                 target="_blank" rel="noopener noreferrer"
                                 >
                              Read Paper
                              </a>
                           </p> : <></>}
                    </div>
                </div> : <></>}
                {!!this && !!this.state && !!this.state.leftUrl && this.state.leftUrl !== '' ? <span className="btn btn-link previous js-scroll px-md-4" onClick={()=> { window.location.href = this.state.leftUrl; return false;}}><ChevronLeft size={48} style={{fontSize: 48 + 'px', color: '#212529'}}/></span> : <></>}
                {!!this && !!this.state && !!this.state.rightUrl && this.state.rightUrl !=='' ? <span className="btn btn-link next js-scroll px-md-4" onClick={()=> { window.location.href = this.state.rightUrl; return false;}}><ChevronRight  size={48} style={{fontSize: 48 + 'px', color: '#212529'}}/></span> : <></>}
                </div>
                <Footer/>
         </>
        );
    }
}

export default Research;
