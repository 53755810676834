import React from "react";

class About extends React.Component {
  constructor() {
    super();
    this.state = {
      skills: [
        { id: "HTML5_skill", content: "HTML5", porcentage: "80%", value: "80" },
        { id: "CSS3_skill", content: "CSS3", porcentage: "75%", value: "75" },
        {
          id: "JavaScript_skill",
          content: "JavaScript",
          porcentage: "90%",
          value: "90"
        },
        { id: "PHP_skill", content: "PHP", porcentage: "70%", value: "70" },
        {
          id: "ReactJS_skill",
          content: "ReactJS",
          porcentage: "80%",
          value: "80"
        },
        {
          id: "Python_skill",
          content: "Python",
          porcentage: "75%",
          value: "75"
        },
        {
          id: "VanillaJS_skill",
          content: "VanillaJS",
          porcentage: "85%",
          value: "85"
        },
        {
          id: "Wordpress_skill",
          content: "Wordpress",
          porcentage: "80%",
          value: "80"
        }
      ],
      about_me: [
        {
          id: "first-p-about",
          content:
            "Being passionate about designing pleasant experiences for users through not just effective but memorable and desirable designs, I adopt a user-centered approach to study behavior and help create intuitive solutions that simplify life. My broad work experience is in the domain of Human-Computer Interaction, social impact, wellbeing, and assistive devices."
        },
        {
          id: "second-p-about",
          content:
            "Currently, a doctoral Candidate at the Empathic Computing Lab within the University of Auckland under the supervision of Prof. Mark Billinghurst, and Associate Professor Suranga Nanayakkara. My research area involves techniques to recognize user context-relevant emotions in Virtual Reality using biosensors such as brainwaves, skin conductance, heart rate variability, and pupil responses. I intend to investigate the user experience, presence, and immersion augmentation through emotion-adaptive Virtual Environment-Human interaction."
        },
        {
          id: "third-p-about",
          content:
            "Lorem ipsum pariatur consectetur laboris occaecat nulla aliqua irure ad deserunt duis. Eiusmod nulla cupidatat labore sint sit aute dolore irure nostrud ut incididunt. Anim laborum reprehenderit labore magna ut dolore quis irure. Labore ea duis deserunt ullamco irure fugiat deserunt ut nisi ea minim proident. Nisi consectetur do non magna duis aliqua minim minim veniam. In occaecat minim qui consequat elit mollit consectetur non id tempor. Amet adipisicing occaecat tempor culpa quis est duis."
        }
      ]
    };
  }

  render() {
    return (
      <section id="experience" className="about-mf sect-pt4 route">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="title-box text-center">
                <h3 className="title-a">Experience</h3>
                {/* <p className="subtitle-a">
                  Incididunt nostrud id aute culpa excepteur pariatur consequat
                  elit culpa nulla enim anim incididunt.
                </p> */}
                <div className="line-mf"></div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <h3 className="mb-4">Work Experience</h3>
              <div className="box-shadow-full">
                <div className="row">
                  <div className="col-md-6">
                  <div className="card cv-cards">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-3 my-auto">
                        <img className="img-fluid" src="https://res.cloudinary.com/kunal-gupta/image/upload/v1642668482/kunalgupta.in/uoa_lp81a3.jpg" alt="..."/>
                      </div>
                      <div className="col-9">
                          <h5 className="card-title">Graduate Researcher</h5>
                          <h6 className="card-sm-text">The University of Auckland</h6>
                          <h6 className="card-sm-text text-muted">Feb 2019 – Present – 2 yrs 10 months Auckland, New Zealand</h6>
                          <p className="card-text">A Ph.D. candidate at the Empathic Computing Laboratory at the University of Auckland. My research work revolves around emotion recognition and environmental adaptation in VR using physiological sensing and contextual information</p>
                      </div>
                    </div>
                  </div>
                  </div>
                  </div>
                  <div className="col-md-6">
                  <div className="card cv-cards">
                  <div className="card-body">
                  <div className="row">
                      <div className="col-3 my-auto">
                        <img className="img-fluid" src="https://res.cloudinary.com/kunal-gupta/image/upload/v1642668522/kunalgupta.in/001-google_kqarqo.png" alt="..."/>
                      </div>
                      <div className="col-9">
                        <h5 className="card-title">UX Researcher</h5>
                        <h6 className="card-sm-text">Google - Contract</h6>
                        <h6 className="card-sm-text text-muted">Apr 2018 – Nov 2018 – 8 months Gurgaon, India</h6>
                        <p className="card-text">As a Researcher working in the Search Social Impact team within Google Search product, I supported User Experience Research efforts across various social impact areas in India such as Education, Civic, and Health.</p>
                      </div>
                    </div>
                  </div>
                  </div>
                  </div>
                </div>
                <div className="row my-4">
                  <div className="col-md-6">
                  <div className="card cv-cards">
                  <div className="card-body">
                  <div className="row">
                      <div className="col-3 my-auto">
                        <img className="img-fluid" src="https://res.cloudinary.com/kunal-gupta/image/upload/v1642668567/kunalgupta.in/ducere_vuxm3w.jpg" alt="..."/>
                      </div>
                      <div className="col-9">
                          <h5 className="card-title">Interaction Designer</h5>
                          <h6 className="card-sm-text">Ducere Technologies Pvt. Ltd.</h6>
                          <h6 className="card-sm-text text-muted">Nov 2015 – Apr 2018 – 2 yrs 6 months Hyderabad, India</h6>
                          <p className="card-text">Engaged closely with the stakeholders, conducted user research, performed requirement analysis, designed hardware and software interactions, developed lo-hi fidelity prototypes by following user-centered design process.</p>
                      </div>
                    </div>
                  </div>
                  </div>
                  </div>
                  <div className="col-md-6">
                  <div className="card cv-cards">
                  <div className="card-body">
                  <div className="row">
                      <div className="col-3 my-auto">
                        <img className="img-fluid" src="https://res.cloudinary.com/kunal-gupta/image/upload/v1642668599/kunalgupta.in/hitlab_dcto8r.png" alt="..."/>
                      </div>
                      <div className="col-9">
                      <h5 className="card-title">Graduate Researcher</h5>
                      <h6 className="card-sm-text">Human Interface Technology Lab NZ</h6>
                      <h6 className="card-sm-text text-muted">Jul 2014 – Jul 2015 – 1 yr 1 month Christchurch, New Zealand</h6>
                      <p className="card-text">Conducted ethnographic research, designed, prototyped, and evaluated emotion sharing application. After contextual inquiry, designed and developed AR-based prototypes for Earthmoving vehicle controller, warehouse navigation.</p>
                      </div>
                    </div>
                  </div>
                  </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                  <div className="card cv-cards">
                  <div className="card-body">
                  <div className="row">
                      <div className="col-3 my-auto">
                        <img className="img-fluid" src="https://res.cloudinary.com/kunal-gupta/image/upload/v1642668567/kunalgupta.in/ducere_vuxm3w.jpg" alt="..."/>
                      </div>
                      <div className="col-9">
                        <h5 className="card-title">Design Engineer</h5>
                        <h6 className="card-sm-text">Ducere Technologies Pvt. Ltd.</h6>
                        <h6 className="card-sm-text text-muted">Jun 2012 – Jun 2013 – 1 yr 1 month Hyderabad, India</h6>
                        <p className="card-text">Designed LeChal, a haptic shoe for the visually impaired, to bring user-driven innovation to the shoes. Lead a team responsible to explore more interactive applications of the existing product.</p>
                      </div>
                    </div>
                  </div>
                  </div>
                  </div>
                  <div className="col-md-6">
                  <div className="card cv-cards">
                  <div className="card-body">
                  <div className="row">
                      <div className="col-3 my-auto">
                        <img className="img-fluid" src="https://res.cloudinary.com/kunal-gupta/image/upload/v1642668635/kunalgupta.in/diabeto_gwufie.jpg" alt="..."/>
                      </div>
                      <div className="col-9">
                        <h5 className="card-title">Design Engineer</h5>
                        <h6 className="card-sm-text">Diabeto (Acquired by Livongo)</h6>
                        <h6 className="card-sm-text text-muted">Jan 2012 – Jun 2012 – 6 months Mumbai, India</h6>
                        <p className="card-text">Designed hardware interaction, developed prototypes, interfacing of Diabeto with existing Glucometers. Contributed to the development of the product and interfacing with the android application.</p>
                      </div>
                    </div>
                  </div>
                  </div>
                  </div>
                </div>
              </div>
              <h3 className="mb-4">Teaching Experience</h3>
              <div className="box-shadow-full">
                <div className="row">
                  <div className="col-md-6">
                  <div className="card cv-cards">
                  <div className="card-body">
                  <div className="row">
                      <div className="col-3 my-auto">
                        <img className="img-fluid" src="https://res.cloudinary.com/kunal-gupta/image/upload/v1642668482/kunalgupta.in/uoa_lp81a3.jpg" alt="..."/>
                      </div>
                      <div className="col-9">
                        <h5 className="card-title">TA, DES 243: Designing Assistive Technologies</h5>
                        <h6 className="card-sm-text">The University of Auckland</h6>
                        <h6 className="card-sm-text text-muted">Jul 2021 – Nov 2021 – 1 semester Auckland, New Zealand</h6>
                      </div>
                    </div>
                  </div>
                  </div>
                  </div>
                </div>
              </div>
              <h3 className="mb-4">Services, Demo and Reviewing</h3>
              <div className="box-shadow-full">
                <div className="row">
                  <div className="col-md-6">
                  <div className="card cv-cards">
                  <div className="card-body">
                  <div className="row">
                      <div className="col-3"></div>
                      <div className="col-9">
                        <h5 className="card-title">Student Volunteer</h5>
                        <h6 className="card-sm-text">SIGGRAPH ASIA 2019, Brisbane</h6>
                        <h6 className="card-sm-text">MeasureCamp 2019, Auckland</h6>
                        <h6 className="card-sm-text">Semi-Permanent 2019, Auckland</h6>
                        <h6 className="card-sm-text">TEDx Christchurch 2014, Christchurch</h6>
                      </div>
                    </div>
                  </div>
                  </div>
                  </div>
                  <div className="col-md-6">
                  <div className="card cv-cards">
                  <div className="card-body">
                  <div className="row">
                      <div className="col-3"></div>
                      <div className="col-9">
                      <h5 className="card-title">Demo</h5>
                      <h6 className="card-sm-text">SIGGRAPH ASIA 2021, Tokyo (To present in December)</h6>
                      <h6 className="card-sm-text">SIGGRAPH ASIA 2019, Brisbane</h6>
                      <h6 className="card-sm-text">Auckland Bioengineering Institute Fundraiser, Auckland</h6>
                      <h6 className="card-sm-text">International Biomechanics Day, Auckland</h6>
                      </div>
                    </div>
                  </div>
                  </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-md-6">
                  <div className="card cv-cards">
                  <div className="card-body">
                  <div className="row">
                      <div className="col-3"></div>
                      <div className="col-9">
                      <h5 className="card-title">Peer Review</h5>
                      <h6 className="card-sm-text">CHI 2020, 2021, 2022</h6>
                      <h6 className="card-sm-text">IEEE VR 2021, 2022</h6>
                      <h6 className="card-sm-text">VRST 2019, 2020, 2021</h6>
                      <h6 className="card-sm-text">ISMAR 2021</h6>
                      <h6 className="card-sm-text">IEEE Transactions on Visualization and Computer Graphics (TVCG) 2021</h6>
                      <h6 className="card-sm-text">Journal on Multimodal User Interfaces 2020</h6>
                      <h6 className="card-sm-text">ACM Transactions on Computer-Human Interaction 2021</h6>
                      </div>
                    </div>
                  </div>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default About;
