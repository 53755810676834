import React from "react";
import "./stars.scss";
class Intro extends React.Component {
  render() {
    return (
    <div id="home" className="intro route">
    <div className="container">
        <div className="row gx-4 gx-lg-5 align-items-center my-5">
               <div className="col-lg-5">
                 <img className="img-fluid rounded mb-4 mb-lg-0" src="https://res.cloudinary.com/kunal-gupta/image/upload/v1642668672/kunalgupta.in/kg_Dp_s1gqgy.png" alt="..."/>
                  <h2 className="my-2">Kunal Gupta</h2>  
                  <h5>HCI Researcher</h5>
                  <div className="socials">
                        <ul>
                        <li>
                            <a
                              href="https://scholar.google.com/citations?user=vyLj2L8AAAAJ&hl=en"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <span className="ico-circle">
                                <i className="ion-university"></i>
                              </span>
                            </a>
                          </li>
                          <li>
                            <a
                              href="mailto:hello@kunalgupta.in"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <span className="ico-circle">
                                <i className="ion-android-mail"></i>
                              </span>
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://twitter.com/kay_J33"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <span className="ico-circle">
                                <i className="ion-social-twitter"></i>
                              </span>
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.linkedin.com/in/kgupta2789/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <span className="ico-circle">
                                <i className="ion-social-linkedin"></i>
                              </span>
                            </a>
                          </li>
                        </ul>
                      </div>
                </div>
               <div className="col-lg-7 text-left">
                  <p className="text-black">Being passionate about designing pleasant experiences for users through not just effective but memorable and desirable designs, I adopt a user-centered approach to study behavior and help create intuitive solutions that simplify life. My broad work experience is in the domain of Human-Computer Interaction, social impact, wellbeing, and assistive devices.</p>
                  <p className="text-black">Currently, a doctoral Candidate at the <a href="http://empathiccomputing.org/" className="color-a">Empathic Computing Lab</a> within the University of Auckland under the supervision of <a href="http://empathiccomputing.org/team/mark-billinghurst/" className="color-a">Prof. Mark Billinghurst</a>, and <a href="https://suranga.info/" className="color-a">Associate Professor Suranga Nanayakkara</a>. My research area involves techniques to recognize user context-relevant emotions in Virtual Reality using biosensors such as brainwaves, skin conductance, heart rate variability, and pupil responses. I intend to investigate the user experience, presence, and immersion augmentation through emotion-adaptive Virtual Environment-Human interaction.</p>
                  {/* <p className="pt-3">
                     <a
                        className="btn btn-outline-primary js-scroll px-4"
                        href="#work"
                        role="button"
                        >
                     View My Work
                     </a>
                  </p> */}
               </div>
        </div>
        </div>
    </div>
    );
  }
}

export default Intro;
