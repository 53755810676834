import React from "react";

class Footer extends React.Component {
  render() {
    return (
    <footer className="py-2">
    <p className="text-black"><small>Made by Kunal Gupta - <a className="color-a" href="mailto:hello@kunalgupta.in">hello [at] kunalgupta [dot] in</a>  </small></p>
    </footer>
    );
  }
}

export default Footer;
