import { PRODUCTION, DEVELOPMENT, LOCAL_WEBSITE_URL } from './const';
import {isEmpty} from 'lodash';

export function getWebsiteUrl () { 
    const REACT_ENV = process.env.NODE_ENV;
    const WEBSITE_URL = !!process.env.REACT_APP_WEBSITE_URL && process.env.REACT_APP_WEBSITE_URL !== '' ? process.env.REACT_APP_WEBSITE_URL : LOCAL_WEBSITE_URL;
    if(!isEmpty(REACT_ENV)){
        switch(REACT_ENV){
            case DEVELOPMENT:
                return WEBSITE_URL;
            case PRODUCTION:
                return WEBSITE_URL;
            default:
                return WEBSITE_URL;
        }
    } else{
        return LOCAL_WEBSITE_URL;
    } 
};

export const getWebsiteUrlValue = () =>{
    return getWebsiteUrl();
}